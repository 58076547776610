#CybotCookiebotDialogHeader {
  display: none !important;
}

#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtons
  .CybotCookiebotDialogBodyButton:not(:last-of-type),
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CybotCookiebotDialogFooter
  .CybotCookiebotDialogBodyButton:not(:first-of-type) {
  padding: 7px 20px !important;
  border-radius: 25px !important;
  min-width: 130px !important;
  line-height: 1.7 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyButtons
  .CybotCookiebotDialogBodyButton:not(:last-of-type):hover,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:hover,
#CybotCookiebotDialogFooter
  .CybotCookiebotDialogBodyButton:not(:first-of-type):hover {
  background: #013e3f !important;
  color: #fff !important;
  opacity: 1 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept {
  background: #013e3f !important;
  color: #fff !important;
  opacity: 1 !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyBottomWrapper {
  display: none !important;
}

@media screen and (min-width: 720px) {
  #CybotCookiebotDialogBodyButtons {
    max-width: none !important;
  }

  #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtons
    .CybotCookiebotDialogBodyButton:not(:last-of-type),
  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
  #CybotCookiebotDialogFooter
    .CybotCookiebotDialogBodyButton:not(:first-of-type) {
    margin-bottom: 0 !important;
  }

  #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
    align-items: center !important;
  }

  #CybotCookiebotDialogFooter {
    width: 33% !important;
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    flex-direction: row !important;
    width: 100% !important;
  }
}
